<template>
    <div class="home">
        <Header />
        <div class="dashboard-layout">
            <Sidebar />
            <div class="body">
                <router-view />
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import Header from './Header.vue'
import Sidebar from './Sidebar.vue'
export default Vue.extend({
  components:{
      Header,
      Sidebar
    }
});
</script>